<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Primary Button Color" vid="primary_button_color" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                label-for="primary_button_color"
              >
              <template v-slot:label>
                Primary Button Color <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="primary_button_color"
                  type="color"
                  v-model="form.primary_button_color"
                  placeholder="Enter primary button color"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Secondary Button Color" vid="secondary_button_color" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                label-for="secondary_button_color"
              >
              <template v-slot:label>
                Secondary Button Color <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="secondary_button_color"
                  type="color"
                  v-model="form.secondary_button_color"
                  placeholder="Enter Secondary button color"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Background color" vid="background_color" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                label-for="background_color"
              >
              <template v-slot:label>
                Background color <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="background_color"
                  type="color"
                  v-model="form.background_color"
                  placeholder="Enter Background color"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Book container color" vid="book_container_color" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                label-for="book_container_color"
              >
              <template v-slot:label>
                Book container color <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="book_container_color"
                  type="color"
                  v-model="form.book_container_color"
                  placeholder="Enter Book container color"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Card header color" vid="card_header_color" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                label-for="card_header_color"
              >
              <template v-slot:label>
                Card header color <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="card_header_color"
                  type="color"
                  v-model="form.card_header_color"
                  placeholder="Enter Card header color"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Card body color" vid="card_body_color" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                label-for="card_body_color"
              >
              <template v-slot:label>
                Card body color <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="card_body_color"
                  type="color"
                  v-model="form.card_body_color"
                  placeholder="Enter Card body color"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Book image" vid="book_image">
              <b-form-group
                label-for="book_image"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Book image <br/>
                <img v-if="bookImageFileUrl" :src="bookImageFileUrl" class="mr-2" height="70" alt="Profile Image">
                <img v-if="!bookImageFileUrl && form.book_image" :src="form.book_image" class="mr-2" height="70" alt="Profile Image">
              </template>

              <b-form-file
                class="mt-3"
                id="book_image"
                v-on:change="changeBookImage"
                accept="image/*"
                ref="file"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Splash screen image" vid="splash_screen_image">
              <b-form-group
                label-for="splash_screen_image"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Splash screen image <br/>
                <img v-if="splashScreenImageFileUrl" :src="splashScreenImageFileUrl" class="mr-2" height="70" alt="Profile Image">
                <img v-if="!splashScreenImageFileUrl && form.splash_screen_image" :src="form.splash_screen_image" class="mr-2" height="70" alt="Profile Image">
              </template>

              <b-form-file
                class="mt-3"
                id="splash_screen_image"
                v-on:change="changeSplashScreenImage"
                accept="image/*"
                ref="file"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <ValidationProvider name="Landing screen image" vid="landing_screen_image">
              <b-form-group
                label-for="landing_screen_image"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Landing screen image <br/>
                <img v-if="landingScreenImageFileUrl" :src="landingScreenImageFileUrl" class="mr-2" height="70" alt="Profile Image">
                <img v-if="!landingScreenImageFileUrl && form.landing_screen_image" :src="form.landing_screen_image" class="mr-2" height="70" alt="Profile Image">
              </template>

              <b-form-file
                class="mt-3"
                id="landing_screen_image"
                v-on:change="changeLandingScreenImage"
                accept="image/*"
                ref="file"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
        <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
        </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { baseURL } from '@/config'

export default {
  props: ['editItem'],
  components: {
  },
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      form: {
        id: '',
        primary_button_color: '#000000',
        secondary_button_color: '#000000',
        background_color: '#000000',
        book_container_color: '#000000',
        card_header_color: '#000000',
        card_body_color: '#000000',
        book_image: '',
        splash_screen_image: '',
        landing_screen_image: ''
      },
      errors: [],
      valid: null,
      loading: false,
      bookImageFileUrl: '',
      splashScreenImageFileUrl: '',
      landingScreenImageFileUrl: ''
    }
  },
  created () {
    if (this.editItem) {
      this.form = JSON.parse(this.editItem)
      console.log('this.form', this.form)
    }
  },
  methods: {
    changeBookImage (event) {
      const inputFile = event.target.files[0]
      const inputFilePath = event.target.value

      const reader = new FileReader()
      if (inputFile.size < 5242880) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i

        if (!allowedExtensions.exec(inputFilePath)) {
          alert('File type should be jpg/jpeg/png/gif!', 'error')
          return false
        }

        reader.onloadend = () => {
          this.bookImageFileUrl = reader.result
        }
        reader.readAsDataURL(inputFile)
        this.form.book_image = inputFile
      } else {
        alert('File size should be maximus 5MB!', 'error')
      }
    },
    changeSplashScreenImage (event) {
      const inputFile = event.target.files[0]
      const inputFilePath = event.target.value

      const reader = new FileReader()
      if (inputFile.size < 5242880) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i

        if (!allowedExtensions.exec(inputFilePath)) {
          alert('File type should be jpg/jpeg/png/gif!', 'error')
          return false
        }

        reader.onloadend = () => {
          this.splashScreenImageFileUrl = reader.result
        }
        reader.readAsDataURL(inputFile)
        this.form.splash_screen_image = inputFile
      } else {
        alert('File size should be maximus 5MB!', 'error')
      }
    },
    changeLandingScreenImage (event) {
      const inputFile = event.target.files[0]
      const inputFilePath = event.target.value

      const reader = new FileReader()
      if (inputFile.size < 5242880) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i

        if (!allowedExtensions.exec(inputFilePath)) {
          alert('File type should be jpg/jpeg/png/gif!', 'error')
          return false
        }

        reader.onloadend = () => {
          this.landingScreenImageFileUrl = reader.result
        }
        reader.readAsDataURL(inputFile)
        this.form.landing_screen_image = inputFile
      } else {
        alert('File size should be maximus 5MB!', 'error')
      }
    },
    async submitData () {
      this.loading = true
      let result = ''
      var formData = new FormData()
      Object.keys(this.form).map(key => {
        formData.append(key, this.form[key])
      })
      if (this.form.id) {
        result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/update_app_ui_feature_data', formData)
      } else {
        result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/store_app_ui_feature_data', formData)
      }
      this.loading = false
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
