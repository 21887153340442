<template>
  <div class="section-wrapper">
    <!-- <div class="breadcrumb-wrapper">
      <b-breadcrumb class="custom-bread"></b-breadcrumb>
    </div> -->
    <b-breadcrumb>
      <b-breadcrumb-item to="/dashboard">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/book-page">Manage Book</b-breadcrumb-item>
      <b-breadcrumb-item active>App Ui Feature</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card class="mt-3">
        <b-card-title>
          <b-row>
            <b-col>
              <h4 class="card-title mb-0 pl-0">App Ui Feature List</h4>
            </b-col>
            <b-col class="text-right">
              <b-button v-if="has_permission('add_new_book_cover_page')" size="sm" variant="info" @click="openAddNewModal()">Add New<i class="ri-add-fill"></i></b-button>
            </b-col>
          </b-row>
        </b-card-title>
        <b-row>
          <b-col>
            <b-overlay :show="loading">
              <b-card>
                <div class="table-wrapper table-responsive">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr style="font-size: 13px;">
                        <th scope="col" class="text-center">SL</th>
                        <th scope="col" class="text-center">Primary button color</th>
                        <th scope="col" class="text-center">Background color</th>
                        <th scope="col" class="text-center">Book container color</th>
                        <th scope="col" class="text-center">Book image</th>
                        <th scope="col" class="text-center">Active</th>
                        <th scope="col" class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody v-for="(item, index) in listData" :key="index">
                      <tr style="font-size: 12px;">
                        <td scope="row" class="text-center">{{ index + pagination.slOffset }}</td>
                        <td class="text-center">
                          <span class="span-style" :style="`background: ${item.primary_button_color}`">{{ item.primary_button_color }}</span>
                        </td>
                        <td class="text-center">
                          <span class="span-style" :style="`background: ${item.background_color}`">{{ item.background_color }}</span>
                        </td>
                        <td class="text-center">
                          <span class="span-style" :style="`background: ${item.book_container_color}`">{{ item.book_container_color }}</span>
                        </td>
                        <td class="text-center">
                          <img v-if="item.book_image" :src="item.book_image" class="mr-2" height="70" alt="book image">
                        </td>
                        <td class="text-center">
                          <b-form-checkbox  v-if="has_permission('active_or_deactive_book_cover_page')" @change="toggleActiveStatus(item)" v-model="item.active" name="check-button" switch>
                          </b-form-checkbox>
                        </td>
                        <td class="text-center">
                          <button v-tooltip="'Content Details'" style="width: 20px !important; height: 19px !important; font-size:10px" href="javascript:" class="action-btn active" @click="editData(item)"><i class="ri-eye-fill"></i></button>
                          <a v-tooltip="'Edit'" v-if="has_permission('edit_book_cover_page')" style="width: 20px !important; height: 20px !important; font-size:10px" href="javascript:" class="action-btn edit" @click="editData(item)"><i class="ri-pencil-fill"></i></a>
                          <a v-tooltip="'Delete'" v-if="has_permission('delete_book_cover_page')" @click="deleteData(item)" style="width: 20px !important; height: 20px !important; font-size:10px" href="javascript:" class="action-btn delete"><i class="ri-delete-bin-2-line"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card>
            </b-overlay>
          </b-col>
      </b-row>
    </b-card>
    <b-modal id="modal-2" ref="detailsModal" size="lg" title="Content Details" :hide-footer="true">
      <Details :editItem="editItem"/>
    </b-modal>
    <b-modal id="modal-1" ref="editModal" size="lg" :title="editItem ? 'Add UI Feature' : 'Update UI Feature'" :hide-footer="true">
      <Form @loadList="getListData" :editItem="editItem"/>
    </b-modal>
    <!-- pagination -->
    <div class="pagination-wrapper mt-4">
      <span>Showing {{ pagination.slOffset }} from {{ pagination.totalRows }} entries</span>
      <b-pagination
        size="sm"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        @input="searchData"
        />
    </div>
  </div>
</template>

<script>
import RestApi, { baseURL } from '@/config'
import Form from './Form.vue'

export default {
  components: {
    Form
  },

  data () {
    return {
      // pagination
      rows: 100,
      currentPage: 1,
      // form data
      search: {
        edition: '',
        position: 0
      },
      value: '',
      listData: [],
      loading: false,
      editItem: '',
      item: ''
    }
  },
  created () {
    this.getListData()
  },
  computed: {
    coverPositionList: function () {
      const list = [
        { value: 'frontpage', text: 'Front Page' },
        { value: 'backpage', text: 'Back Page' }
      ]
      return list
    }
  },
  methods: {
    openAddNewModal () {
      this.editItem = ''
      this.$refs.editModal.show()
    },
    editData (item) {
      this.editItem = JSON.stringify(item)
      this.$refs.editModal.show()
    },
    detailsData (item) {
      this.editItem = item
      this.$refs.detailsModal.show()
    },
    searchData () {
      this.getListData()
    },
    clearData () {
      this.search = {
        page_no: '',
        page_type: 0
      }
      this.getListData()
    },
    async getListData () {
      this.loading = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      var result = await RestApi.getData(baseURL, 'api/v1/admin/ajax/get_app_ui_feature_list', params)
      if (result.success) {
        this.listData = result.data.data
        this.paginationData(result.data)
      }
      this.loading = false
    },
    async toggleActiveStatus (item) {
      this.loading = true
      var result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/toggle_app_ui_feature_active_status', item)
      if (result.success) {
        this.$toast.success({ title: 'Success', message: result.message })
        this.getListData()
      }
      this.loading = false
    },
    deleteData (item) {
      this.$swal({
        title: 'Are you sure to delete?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteConfirmation(item)
        }
      })
    },
    async deleteConfirmation (item) {
      this.loading = true
      var result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/delete_app_ui_feature_data', item)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.getListData()
      }
      this.loading = false
    }
  }
}
</script>
<style scoped>
 .fontStyle {
  text-align: justify
 }
 .span-style {
  padding: 6px 17px;
  border: 4px solid #dedddd;
  color: #FFF;
  border-radius: 5px;
 }
</style>
